import { isValidGuid } from '../utilities/validations';
import { Cache } from '../utilities/cache';

export const extractDataFromURL = () => {
  const urlHost = window.location.hostname.toLocaleLowerCase();
  const urlPath = window.location.pathname.toLocaleLowerCase();
  const isProductPlayerDomain = urlHost === `productplayer${window.TOC_ENDPOINT_ENV}cet.ac.il`;

  let pathName = urlPath.indexOf('/') === 0 ? urlPath.substr(1) : urlPath;
  pathName = pathName.indexOf('/') === pathName.length - 1 ? pathName.substr(0, pathName.length - 1) : pathName;

  let pathNameParts = pathName.split('/').filter(Boolean);
  let productName = isProductPlayerDomain ? (pathNameParts.length > 0 ? pathNameParts[0] : '') : urlHost.split('.')[0];
  let nodeKey = '';
  let environmentId = '';
  let audienceId = ''; // PATCH only for myofek (hopefully till they will use environmentId correctly)
  let isStudentPreview = false;

  if (!window.IS_OLAMON && !window.IS_CYBERSQUAD) {
    nodeKey = pathNameParts.length > 1 ? pathNameParts[1] : '';
  } else {
    const olamonPages = ['login', 'intro', 'welcome', 'prologue'];
    pathNameParts = pathNameParts.filter(part => !olamonPages.includes(part.toLowerCase())).filter(Boolean);
    nodeKey = pathNameParts.length > 1 ? pathNameParts[pathNameParts.length - 1] : '';
  }

  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    environmentId = searchParams.get('environmentId');
    environmentId = environmentId && isValidGuid(environmentId) ? environmentId : '';
    audienceId = searchParams.get('audience');
    audienceId = audienceId && isValidGuid(audienceId) ? audienceId : '';
    isStudentPreview = searchParams.get('isStudentPreview') === 'true';

    // Check for campaign URL params - and save full campaign URL in cache (if any campaign is specified)
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');

    if (utmSource && utmMedium) {
      const campaignData = {
        campaignPagePath: window.location.pathname + window.location.search,
        campaignPageURL: window.location.href,
        utmSource,
        utmMedium,
        utmCampaign
      };

      Cache.set('campaignData', campaignData, Cache.method.sessionStorage);
    }
  }

  const nodeKeyParts = nodeKey.split('_').filter(Boolean);

  let isValidKey = nodeKeyParts.length > 0 && isValidGuid(nodeKeyParts[0]);

  if (isValidKey && nodeKeyParts.length > 1) {
    isValidKey = isValidGuid(nodeKeyParts[1]);
  }

  return {
    productName,
    pathName,
    domain: `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`,
    nodeKey: nodeKey ? (isValidKey ? nodeKey : 'key-not-valid') : '',
    folderId: nodeKeyParts.length > 0 ? (isValidKey ? nodeKeyParts[0] : 'key-not-valid') : '',
    documentId: nodeKeyParts.length > 1 ? (isValidKey ? nodeKeyParts[1] : 'key-not-valid') : '',
    environmentId,
    audienceId,
    isStudentPreview
  };
};

export const initAccessibilityLang = (lang, sitekey = '245ce95f2d0009fdfb7cf218a2c6122a') => {
  window.interdeal.Menulang = lang;
  window.interdeal.sitekey = sitekey;

  if (window.TOC_DEVICE_TYPE === 'PC' && !window.IS_OLAMON) {
    window.interdeal.Position = 'Left';
  } else {
    window.interdeal.Position = lang === 'he' || lang === 'ar' ? 'Right' : 'Left';
  }

  const script = document.createElement('script');
  script.src = `https://cdn${window.TOC_ENDPOINT_ENV}cet.ac.il/libs/cet.nagich.provider/1.0/cet.nagich.provider.js`;
  document.body.appendChild(script);

  document.documentElement.setAttribute('lang', lang);
};

export const getParentPage = (rootDocumentId, treeData, routes = null) => {
  const urlData = extractDataFromURL();
  let parentPage = '/';

  if (treeData) {
    let currentFolderId = urlData.folderId;
    let currentDocumentId = urlData.documentId;

    if (routes?.length) {
      let isRouteExist = urlData.pathName === '';

      while (!isRouteExist && currentFolderId !== rootDocumentId && treeData[currentFolderId]) {
        let currentRoute = `/${currentFolderId}`;
        let filteredRoutes = routes.filter(route => route.path === currentRoute);
        if (filteredRoutes.length > 0) {
          parentPage = currentRoute;
          isRouteExist = true;
        } else {
          currentFolderId = treeData[currentFolderId]?.parentId;
        }
      }
    } else {
      if (currentDocumentId) {
        parentPage = `/${currentFolderId}`;
      } else if (currentFolderId) {
        const parentFolderId = treeData[currentFolderId]?.parentId;

        if (parentFolderId && parentFolderId !== rootDocumentId) {
          parentPage = `/${parentFolderId}`;
        }
      }
    }
  }

  return parentPage;
};
